.list-container {
    padding: 5px;
}

.guest-list {
    border: 1px solid #ff4c61;
    border-radius: 5px;
    padding: 10px;
}

.list-header {
    display: flex;
    justify-content: space-between;
    height: 35px;
    align-items: center;
}

.add-btn {
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    border: 3px;
    color: #ff4c61;
    background-color: transparent;
    border: none;
    outline: none;
}

.list-item {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ff4c61;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-name {
    font-size: 1.2rem;
    color: #343a40;
}

.list-count {
    font-size: 1rem;
    color: #6c757d;
    margin-left: 10px;
    background-color: #e9ecef;
    padding: 5px 10px;
    border-radius: 5px;
}

.empty-list {
    font-size: 1.2rem;
    color: #6c757d;
    text-align: center;
    margin-top: 50%;
}

/* create user modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.modal-content h2 {
    margin-top: 0;
}

.modal input {
    display: block;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 1px #ccc;
    transition: box-shadow 0.2s ease-in-out;
}

.modal input:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #ff4c61;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.save-btn {
    background-color: #ff4c61;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.cancel-btn {
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.table-list {
    border: 1px solid #ff4c61;
    border-radius: 5px;
    padding: 10px;
}

.secondary-navbar {
    padding: 20px;
    height: 80px;
}

.secondary-navbar .btn-outline-primary {
    color: #ff4c61;
    border-color: #ff4c61;
    margin: 5px;
}

.secondary-navbar .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #ff4c61;
    border-color: #ff4c61;
}

.table-style-images {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.table-style-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 5px;
    border: 2px solid transparent;
    transition: border-color 0.3s;
}

.table-style-image-container.selected {
    border-color: #ff637d;
}

.table-style-image-container img {
    width: 80px;
    height: 80px;
}

.table-style-image-container span {
    margin-top: 5px;
}

.qrcode-container {
    position: absolute;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 8px;
    margin-top: 10px;
}

.close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
}