.navbar {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  z-index: 100;
}

.navbar-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-buttons {
  display: flex;
  align-items: center;
}

.navbar-button {
  background-color: #ff637d;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navbar-button:hover {
  background-color: #ff4c61;
}