.home-page {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  padding: 0 20px;
}

.home-title {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.home-description {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 40px;
}

.home-button {
  background-color: #ff637d;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #ff4c61;
}