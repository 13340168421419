.table-layout-page {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Add other styles you want for the table-layout-page */
}

.tables-container {
    /* Add other styles you want for the container */
    background-color: #f0f0f0;
    background-image: linear-gradient(0deg, rgba(200, 200, 200, 0.5) 1px, transparent 1px),
        linear-gradient(90deg, rgba(200, 200, 200, 0.5) 1px, transparent 1px);
    background-size: 20px 20px;
    border-color: #ff637d;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    margin-bottom: 1rem;
}

.save-button,
.reset-button {
    margin-right: 1rem;
}

.save-button {
    background-color: #ff637d !important;
    border-color: #ff637d !important;
}

.reset-button {
    color: #ff637d !important;
    background-color: #fff !important;
    border-color: #ff637d !important;
}

.back-button {
    color: #ff637d !important;
    background-color: #fff !important;
    border-color: #fff !important;
}

.save-button:hover,
.reset-button:hover,
.save-button:focus,
.reset-button:focus {
    background-color: #ff4c61 !important;
    border-color: #ff4c61 !important;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.size-input {
    margin: 0 1rem;
    width: 80px;
}

.search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.search-bar input {
    width: 300px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-guest-list {
    position: absolute;
    width: 300px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.guest-item {
    padding: 0.5rem;
    cursor: pointer;
}

.guest-item:hover {
    background-color: #f0f0f0;
}