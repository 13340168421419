.date-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}

.selector-year,
.selector-month,
.selector-day {
  font-size: 2rem;
  font-weight: bold;
  color: #ff637d;
  text-align: center;
  width: 4rem;
  height: 4rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #fff;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.selector-year {
  width: 6rem;
}

.selector-arrow {
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
}

.up-arrow {
  margin-bottom: -0.5rem;
}