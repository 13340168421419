.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-title {
  margin-top: 0;
  font-size: 36px;
  font-weight: bold;
  color: #444;
}

.form-group {
  margin-bottom: 20px;
}

.login-input {
  font-size: 18px;
  padding: 12px 16px;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  color: #444;
  background-color: #f5f5f5;
}

.login-input::placeholder {
  color: #999;
}

.login-button {
  background-color: #ff4c61;
  color: #fff;
  font-size: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 20px;
}

.login-button:hover {
  background-color: #ff4c61;
}

.login-footer {
  margin-top: 20px;
  color: #999;
  font-size: 16px;
}

.login-footer a {
  color: #0077cc;
  text-decoration: none;
}
