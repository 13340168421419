.page-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
}

.create-wedding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    ;
    padding: 0 20px;
}

.dashboard-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ff637d;
    margin-bottom: 2rem;
}

.create-button {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    background-color: #ff637d;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.create-button:hover {
    background-color: #e3566c;
}


.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.username {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.wedding-date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.modify-btn {
    margin-left: 10px;
    padding: 5px;
    border: none;
    background: none;
    cursor: pointer;
}

.modify-btn:hover {
    background-color: #eee;
    border-radius: 50%;
}

.modify-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

label {
    margin-right: 10px;
}

.btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.save-btn,
.cancel-btn {
    margin: 10px;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.save-btn {
    background-color: #27ae60;
}

.cancel-btn {
    background-color: #c0392b;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}